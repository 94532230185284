import React, { useRef, useState } from "react";
import Input from "./Input";
import { db, uploadFile } from "../../FirebaseConfig.ts";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import Spinner from "../../components/Spinner";
import useUpdateDoc from "../../hooks/useUpdateDoc.js";

function FormStepTwo({
  setActiveMoney,
  activeMoney,
  setStep,
  data,
  setData,
  files,
  setFiles,
}) {
  const form = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sended, setSended] = useState(false);

  const { handleUpdate } = useUpdateDoc();

  const sendCredit = async (e) => {
    e.preventDefault();

    setLoading(true);
    //sube el archivo a firebase

    const name = `${data.name} ${data.lastName}`;

    try {
      const uploadPromises = Object.keys(files).map(async (key) => {
        const file = files[key];

        if (file) {
          // Solo subimos si el archivo está presente
          const url = await uploadFile(file, `${name}/${key}`);
          return { [key]: url }; // Retornamos un objeto con el nombre del archivo como clave
        }
        return null;
      });

      const uploadResults = await Promise.all(uploadPromises);

      // Convertir los resultados en un objeto de URLs
      const fileUrls = uploadResults.reduce((acc, curr) => {
        if (curr) {
          return { ...acc, ...curr };
        }
        return acc;
      }, {});

      const res = await addDoc(collection(db, "companies"), {
        ...data,
        ...fileUrls,
      });

      handleUpdate("companies", res.id, { uuid: res.id });

      setData({
        name_company: "",
        email: "",
        name: "",
        lastName: "",
        identity: "",
        expedition: "",
      });
      setFiles({
        rut: "",
        fot_cedula: "",
        cam_comercio: "",
      });
      setError("");
      setSended(true);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setError(
        "Ha ocurrido un error al mandar la solicitud, por favor intente nuevamente"
      );
      setSended(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form className="w-full mx-auto mt-5" ref={form} onSubmit={sendCredit}>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Nombre de la empresa"
            onChange={(e) => {
              setData({ ...data, name_company: e.target.value });
            }}
            value={data.name_company}
          />
          <Input
            label="Correo de la empresa"
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
            value={data.email}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Nombres Rep. Legal"
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            value={data.name}
          />
          <Input
            label="Apellidos  Rep. Legal"
            onChange={(e) => {
              setData({ ...data, lastName: e.target.value });
            }}
            value={data.lastName}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="Cédula Rep. Legal"
            onChange={(e) => {
              setData({ ...data, identity: e.target.value });
            }}
            value={data.identity}
          />

          <Input
            label="Lugar de Expedición"
            onChange={(e) => {
              setData({ ...data, expedition: e.target.value });
            }}
            value={data.expedition}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
          <Input
            label="RUT"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, rut: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Input
            label="Fotocopia Cédula Rep. Legal"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, fot_cedula: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>
        <div className="grid grid-cols-1">
          <Input
            label="Certificado Cámara de Comercio"
            type="file"
            onChange={(e) => {
              setFiles({ ...files, cam_comercio: e.target.files[0] });
            }}
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>

        <div className="flex items-center justify-center py-3">
          <input
            id="link-checkbox"
            type="checkbox"
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            required
          />
          <label
            htmlFor="link-checkbox"
            className="ms-2 text-sm font-medium text-white"
          >
            Estoy de acuerdo con los{" "}
            <a href="#" className="text-robin hover:underline">
              términos y condiciones
            </a>
            .
          </label>
        </div>
        {error && <p className="text-primary">{error}</p>}
        {sended && <p className="text-green-400 mb-3">Registro exitoso</p>}
        <button
          type="submit"
          className="text-white rounded-lg px-5 py-2.5 text-center bg-secondary w-full font-bold text-xl"
          disabled={loading}
        >
          {loading ? <Spinner /> : "Registrar Empresa"}
        </button>
      </form>
    </>
  );
}

export default FormStepTwo;
