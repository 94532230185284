import { es } from "dayjs/locale/es";
import { useState } from "react";
import { PDFDocument, rgb } from "pdf-lib";
import { planPagos } from "../../utils/planPagos";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatNumber } from "../../utils/format";
import { capitalize } from "./capitalize";
import { formatCurrency } from "../../utils/formatCurrency";
dayjs.extend(relativeTime);
dayjs.locale("es");

function WithoutCodeudor({ data, status }) {
  console.log(data);
  const [loading, setLoading] = useState(false);

  const { schedule, totalCredit, totalInterest } = planPagos(data);

  const generarCertificado = async () => {
    setLoading(true);

    // Cargar el PDF de plantilla
    const pdfUrl = "/documentos_sin_codeudor.pdf";
    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    );

    // Cargar el PDF en pdf-lib
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Obtener la primera página y agregar texto
    const page = pdfDoc.getPage(0);

    /////////////credito

    page.drawText(`${data.numberCredit}`, {
      x: 70,
      y: 612,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${formatNumber(data.ammount)}`, {
      x: 270,
      y: 612,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText("Libre Inversión", {
      x: 420,
      y: 612,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${formatNumber(schedule[0]?.cuotaAPagar.toFixed(0))}`, {
      x: 70,
      y: 562,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule?.length}`, {
      x: 250,
      y: 562,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${data.tasa} %`, {
      x: 350,
      y: 562,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${formatNumber(totalCredit.toFixed(0))}`, {
      x: 450,
      y: 562,
      size: 9,
      color: rgb(0, 0, 0),
    });

    ////primer descuentop

    page.drawText(`${schedule[0]?.fechaPago.split("/")[2]}`, {
      x: 53,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[0]?.fechaPago.split("/")[1]}`, {
      x: 133,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[0]?.fechaPago.split("/")[0]}`, {
      x: 203,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[0]?.fechaPago.split("/")[2]}`, {
      x: 253,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[0]?.fechaPago.split("/")[1]}`, {
      x: 300,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[0]?.fechaPago.split("/")[0]}`, {
      x: 350,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    ///Vencimiento final

    page.drawText(`${schedule[schedule.length - 1]?.fechaPago.split("/")[2]}`, {
      x: 400,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[schedule.length - 1]?.fechaPago.split("/")[1]}`, {
      x: 470,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${schedule[schedule.length - 1]?.fechaPago.split("/")[0]}`, {
      x: 540,
      y: 515,
      size: 9,
      color: rgb(0, 0, 0),
    });

    /////

    ///nombres deudores
    page.drawText(`${data.name} ${data.lastName}`, {
      x: 70,
      y: 192,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${formatCurrency(data.identity)}`, {
      x: 96,
      y: 180,
      size: 9,
      color: rgb(0, 0, 0),
    });
    ///codeudor
    page.drawText("", {
      x: 330,
      y: 192,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page.drawText("", {
      x: 352,
      y: 180,
      size: 9,
      color: rgb(0, 0, 0),
    });

    //////

    // Obtener la segunda página y agregar texto
    const page2 = pdfDoc.getPage(1);

    /////////////credito

    page2.drawText(`${data.name} ${data.lastName}`, {
      x: 100,
      y: 629,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page2.drawText(`${formatCurrency(data.identity)}`, {
      x: 150,
      y: 616,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page2.drawText(`${data.expedition}`, {
      x: 300,
      y: 616,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page2.drawText(`${dayjs(data.start_date).format("DD")}`, {
      x: 300,
      y: 318,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page2.drawText(`${capitalize(dayjs(data.start_date).format("MMMM"))}`, {
      x: 440,
      y: 318,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page2.drawText(`${capitalize(dayjs(data.start_date).format("YYYY"))}`, {
      x: 100,
      y: 305,
      size: 9,
      color: rgb(0, 0, 0),
    });

    //Deudor

    page2.drawText(`${data.name} ${data.lastName}`, {
      x: 130,
      y: 234,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page2.drawText(`${formatCurrency(data.identity)}`, {
      x: 155,
      y: 222,
      size: 9,
      color: rgb(0, 0, 0),
    });

    ///

    // Obtener la tercera página y agregar texto
    const page3 = pdfDoc.getPage(2);

    page3.drawText(`${data.name} ${data.lastName}`, {
      x: 75,
      y: 172,
      size: 9,
      color: rgb(0, 0, 0),
    });

    page3.drawText(`${formatCurrency(data.identity)}`, {
      x: 105,
      y: 162,
      size: 9,
      color: rgb(0, 0, 0),
    });

    const logoUrl = "/logo_lendy.png"; // Ruta al logo
    const logoBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoBytes);
    const logoDims = logoImage.scale(0.07); // Escala el logo

    // Obtener la cuarta página y agregar tabla
    const newPage = pdfDoc.addPage([595.28, 841.89]);

    newPage.drawImage(logoImage, {
      x: newPage.getWidth() - logoDims.width - 20, // Ajustar posición horizontal
      y: newPage.getHeight() - logoDims.height - 20, // Ajustar posición vertical
      width: logoDims.width,
      height: logoDims.height,
    });

    // Configurar las posiciones y dimensiones de la tabla
    const tableStartX = 50;
    const tableStartY = 750;
    const rowHeight = 20;
    const columnWidths = [50, 90, 80, 90, 70, 70, 70]; // Ancho de cada columna
    const headers = [
      "Cuotas",
      "Fecha de pago",
      "Valor préstamo",
      "Cuota a pagar",
      "Capital",
      "Interés",
      "Saldo deuda",
    ];

    const headerBackgroundColor = rgb(3 / 255, 32 / 255, 99 / 255);

    // Dibujar fondo para los encabezados
    headers.forEach((header, i) => {
      const x =
        tableStartX + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
      newPage.drawRectangle({
        x,
        y: tableStartY - rowHeight,
        width: columnWidths[i],
        height: rowHeight,
        color: headerBackgroundColor, // Color gris claro
      });

      newPage.drawText(header, {
        x: x + 5, // Margen izquierdo
        y: tableStartY - rowHeight + 5, // Margen inferior
        size: 10,
        color: rgb(1, 1, 1),
      });
    });

    // Dibujar bordes y contenido de las filas
    schedule.forEach((row, rowIndex) => {
      headers.forEach((_, colIndex) => {
        const x =
          tableStartX +
          columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0);
        const y = tableStartY - (rowIndex + 2) * rowHeight;

        // Dibujar bordes de celdas
        newPage.drawRectangle({
          x,
          y,
          width: columnWidths[colIndex],
          height: rowHeight,
          borderColor: rgb(0, 0, 0), // Color del borde
          borderWidth: 1, // Grosor del borde
        });

        // Agregar texto dentro de las celdas
        const text = [
          row.cuota.toString(),
          row.fechaPago.toString(),
          row.valorPrestamo.toLocaleString(),
          row.cuotaAPagar.toLocaleString(),
          row.capital.toLocaleString(),
          row.interes.toLocaleString(),
          row.saldoDeuda.toLocaleString(),
        ][colIndex];

        newPage.drawText(text, {
          x: x + 5, // Margen izquierdo
          y: y + 5, // Margen inferior
          size: 10,
          color: rgb(0, 0, 0),
        });
      });
    });

    // Obtener la segunda página y agregar texto
    const page4 = pdfDoc.getPage(3);

    page4.drawText("Plan de pagos", {
      x: 45,
      y: 782,
      size: 18,
      color: rgb(0, 0, 0),
    });

    page4.drawText("En prueba de aceptación", {
      x: 55,
      y: 102,
      size: 10,
      color: rgb(0, 0, 0),
    });
    page4.drawText("____________________", {
      x: 55,
      y: 82,
      size: 10,
      color: rgb(0, 0, 0),
    });
    page4.drawText("Firma", {
      x: 55,
      y: 68,
      size: 10,
      color: rgb(0, 0, 0),
    });
    page4.drawText("Nombre:", {
      x: 55,
      y: 48,
      size: 10,
      color: rgb(0, 0, 0),
    });
    page4.drawText("Cédula:", {
      x: 55,
      y: 38,
      size: 10,
      color: rgb(0, 0, 0),
    });

    // Guardar el PDF modificado como bytes
    const pdfBytes = await pdfDoc.save();

    // Crear un Blob con los bytes del PDF modificado
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Crear una URL para el Blob
    const url = URL.createObjectURL(blob);

    // Abrir una nueva pestaña con el PDF generado
    const newWindow = window.open(url, "_blank");

    setTimeout(() => {
      newWindow.document.title = "Solicitud sin codeudor - Jhon García";
    }, 100); // Ajusta el tiempo según sea necesario

    setLoading(false);
  };

  return (
    <div>
      <p className="text-xs text-gray-700">
        {status !== "aprobado"
          ? "No puedes descargar esta solicitud si el estado no es aprobado"
          : false}
      </p>
      <button
        onClick={() => generarCertificado()}
        className={`!mx-0  rounded-lg py-1 px-2 text-black font-semibold mt-3 ${
          status !== "aprobado" ? "bg-primary/50" : "bg-primary"
        }`}
        aria-disabled={status !== "aprobado" ? true : false}
        disabled={status !== "aprobado" ? true : false}
      >
        Descargar Solicitud Sin Codeudor
      </button>
    </div>
  );
}

export default WithoutCodeudor;
