import Spinner from "../../../../components/Spinner";
import { useSnapshot } from "../../../../hooks/useSnapshot";
import useDeleteDoc from "../../../../hooks/useDeleteDoc";
import { formatNumber } from "../../../../utils/format";
import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import ModalContainer from "./ModalContainer";
import { Link } from "react-router-dom";
import { useSnapshotWhere } from "../../../../hooks/useSnapshotWhere";
import useUpdateDoc from "../../../../hooks/useUpdateDoc";
import { obtenerFechaActual } from "../../../../utils/actualDate";

function Table() {
  const { data, isFetching } = useSnapshotWhere("requests", {
    filter: {
      firstCondition: "status",
      secondCondition: "==",
      thirdCondition: "pendiente",
    },
  });
  const { handleDelete, fetching: fetchingDelete } = useDeleteDoc();
  const [showModal, setShowModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});
  const [fetching, setFetching] = useState(false);
  const [status, setStatus] = useState("aprobado");

  const { handleUpdate } = useUpdateDoc();

  const handleUpdateRequest = async (el) => {
    let data = { status: status };

    if (status === "aprobado") data.start_date = obtenerFechaActual();
    try {
      setFetching(true);
      handleUpdate("requests", el.id, data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const hDelete = async (id) => {
    try {
      const r = window.confirm("¿Estás seguro de eliminar esta solicitud?");

      if (!r) return;

      handleDelete("requests", id);
    } catch (error) {}
  };

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ModalContainer currentRequest={currentRequest} />
      </Modal>
      <div
        className={`relative shadow-md sm:rounded-lg ${
          isFetching ? "" : "overflow-x-auto"
        }`}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Nombres
              </th>
              <th scope="col" className="px-6 py-3">
                Compañia
              </th>
              <th scope="col" className="px-6 py-3">
                Celular
              </th>
              <th scope="col" className="px-6 py-3">
                Cédula
              </th>
              <th scope="col" className="px-6 py-3">
                Monto
              </th>
              <th scope="col" className="px-6 py-3">
                Estado
              </th>
              <th scope="col" className="px-6 py-3">
                Acción
              </th>
            </tr>
          </thead>

          <tbody className="bg-white">
            {isFetching ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan="100%" className="text-center text-lg py-3">
                  Aún no hay solicitudes de créditos
                </td>
              </tr>
            ) : (
              data?.map((el, index) => {
                return (
                  <tr
                    className="odd:bg-white even:bg-gray-50  border-b"
                    key={el.id}
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {`${el.name} ${el.lastName}`}
                    </th>
                    <td className="px-6 py-4">
                      {el.companyId ? (
                        <Link
                          to={`/plataforma/compania/${el.companyId}/${el.id}`}
                        >
                          <button className="bg-blue-700 rounded-lg px-2 py-1 text-white">
                            Ver
                          </button>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="px-6 py-4">{el.cellphone}</td>
                    <td className="px-6 py-4">{el.identity}</td>
                    <td className="px-6 py-4">{formatNumber(el.ammount)}</td>
                    <td className="px-6 py-4 flex items-center gap-2">
                      <select
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                        id="countries"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option value="aprobado">Aprobado</option>
                        <option value="rechazado">Rechazado</option>
                      </select>
                      <button
                        onClick={() => handleUpdateRequest(el)}
                        disabled={fetching}
                        aria-disabled={fetching}
                        className="bg-blue-700 rounded-lg text-white p-2.5"
                      >
                        A
                      </button>
                    </td>

                    <td className="px-6 py-4">
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setCurrentRequest(el);
                        }}
                        className="font-medium text-blue-600 hover:underline"
                      >
                        Editar
                      </button>
                      <Link
                        to={`/plataforma/simulador/${el.identity}/${el.id}`}
                      >
                        <button
                          onClick={() => {
                            setShowModal(true);
                            setCurrentRequest(el);
                          }}
                          className="font-medium text-gray-600 hover:underline ml-2"
                        >
                          Plan de pagos
                        </button>
                      </Link>
                      <button
                        onClick={() => hDelete(el.id)}
                        className="font-medium text-red-800 hover:underline ml-3"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;
