import React, { useState } from "react";
import LogoBlack from "../LogoBlack";
import Form from "../Form";
import Features from "./features";
import FormCompany from "../FormCompany";
import FormCodeudor from "../FormCodeudor";
import { planPagos } from "../../utils/planPagos";
import { useSnapshot } from "../../hooks/useSnapshot";
import dayjs from "dayjs";
import TablePagos from "../../pages/plataforma/plan-de-pagos/table";
import { Link } from "react-router-dom";

function Banner({ company, codeudor, dataCodeudor }) {
  const { data: tasa, isFetching } = useSnapshot("tasa");

  const [sended, setSended] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    knowMoney: true,
    ammount: "10000000",
    month: "12",
    expedition: "",
    name: "",
    lastName: "",
    company: "",
    NIT: "",
    address: "",
    cellphone: "",
    identity: "",
    email: "",
  });

  const [files, setFiles] = useState({
    cert_laboral: "",
    comp_nomina: "",
    fot_cedula: "",
    dec_renta: "",
  });

  const { schedule, totalCredit, totalInterest } = planPagos({
    ...data,
    tasa: tasa[0]?.number,
    start_date: dayjs().format("YYYY-MM-DD"),
  });
  return (
    <section className="">
      <div className="flex items-center justify-center bg-white py-6 px-6">
        <LogoBlack />
        <div className="absolute top-7 right-7 hidden md:block">
          <Link to="/plataforma">
            <button className="bg-secondary rounded-lg px-3 py-1 text-white">
              Portal <span className="">Administrador</span>
            </button>
          </Link>
        </div>
      </div>
      {step === 2 || sended ? (
        <div className="mt-0 p-10">
          <p className="mb-3 text-xl">
            {step === 2 ? (
              <div className="border-b border-gray-400 pb-3">
                <div>
                  <p>Este sería tu plan de pagos, deseas continuar?</p>
                  <div className="flex gap-3 items-center justify-center">
                    <button
                      className="bg-gray-200 rounded-lg px-3 py-1 text-sm text-black mt-2"
                      onClick={() => setStep(0)}
                    >
                      Volver
                    </button>
                    <button
                      className="bg-blue-600 rounded-lg px-3 py-1 text-sm text-white mt-2"
                      onClick={() => setStep(1)}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              "Solicitud enviada correctamente, este sería tu plan de pagos:"
            )}
          </p>
          <p className="mb-4">Tasa: {tasa[0]?.number} % anual</p>
          <div className="md:mx-0 -mx-6 overflow-scroll">
            <TablePagos loading={false} schedule={schedule} />
          </div>
        </div>
      ) : (
        <div className="grid max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 mt-6 p-10">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-primary">
              {company
                ? "Regístrate como empresa"
                : codeudor
                ? "Completa tus datos como codeudor"
                : "Creditos de libranza"}
            </h1>
            {company || codeudor ? (
              ""
            ) : (
              <>
                <p className="max-w-2xl mb-6 font-light text-black lg:mb-8 md:text-lg lg:text-xl">
                  Obtén el financiamiento que necesitas con la tranquilidad y
                  facilidad que mereces. Con nuestros créditos de libranza,
                  podrás acceder a préstamos con tasas competitivas, plazos
                  cómodos y descuentos directos desde tu salario o pensión. Nos
                  encargamos de hacer el proceso rápido y sencillo para que
                  puedas cumplir tus metas sin preocupaciones.
                </p>
                <Features />
              </>
            )}
          </div>
          <div className="lg:mt-0 lg:col-span-5 lg:flex">
            {company ? (
              <FormCompany />
            ) : codeudor ? (
              <FormCodeudor dataCodeudor={dataCodeudor} />
            ) : (
              <Form
                setSended={setSended}
                sended={sended}
                data={data}
                setData={setData}
                setFiles={setFiles}
                files={files}
                step={step}
                setStep={setStep}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default Banner;
